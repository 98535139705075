import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import "../styles/components/_share-toolbox.scss";
import { googleScriptLoadingDelay, isBrowser } from "../utils/helperFn";

const Share = (props) => {
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    setTimeout(() => {
      const headElement = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://platform-api.sharethis.com/js/sharethis.js#property=64569299a4982d0019d17389&product=sop`;
      headElement.append(script);
      try {
        //window.addthis.layers.refresh();
        window.__sharethis__.initialize();
      } catch (e) {
        console.log(e);
      }
    }, googleScriptLoadingDelay() * (isMobile ? 2 : 1));
  }, [])

  return <div className={`sharethis-inline-share-buttons ${props?.className}`}
              data-url={props.url}
              data-title={props.title}
              data-description={props.description}
              data-media={props.image}>
    {/*<div className="custom_images">
            <a className="addthis_button_more"><img src="/images/share.svg" width="32" height="32" border="0" alt="More.."/></a>
          </div>*/}
  </div>;
}

export default Share;
