import React, { useState } from "react";
import Share from "../components/sharing";

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [place, setPlace] = useState(null)
  const [plan, setPlan] = useState(null)
  const [loader, setLoader] = useState(false)
  const [pageTitle, setPageTitle] = useState(null)
  const [isSharingOptionsModalOpen, setIsSharingOptionsModalOpen] = useState(false)
  const [sharingOptionsModalContent, setSharingOptionsModalContent] = useState(<Share/>)

  return (
    <GlobalContext.Provider
      value={{
        place,
        setPlace,
        plan,
        setPlan,
        loader,
        setLoader,
        pageTitle,
        setPageTitle,
        isSharingOptionsModalOpen,
        setIsSharingOptionsModalOpen,
        sharingOptionsModalContent,
        setSharingOptionsModalContent
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalProvider }
