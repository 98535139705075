export const REVOLUT_ENV = process.env.GATSBY_REVOLUT_ENV;
export const SERVER_PATH = process.env.GATSBY_SERVER_URL;
export const AGE_GROUP_SERVER_PATH = "https://api.agegroup.io";
export const AGE_FRIENDLY_SEAL_URL = "/images/seal.svg"
export const AGE_FRIENDLY_SEAL_GREEN_URL = "/images/seal_green.png"
//export const AGE_FRIENDLY_UNVERIFIED_SEAL_URL = "/images/seal-unverified.svg"
export const AGE_FRIENDLY_UNVERIFIED_SEAL_URL = "/images/seal-unverified.png"
export const SCRIPT_LOADING_TIME_WAIT = 1200
export const VERIFICATION_ALREADY_STARTED_STORAGE_KEY = "verification_started"
export const diningOptions = [
  { value: "Serves alcohol", label: "Serves alcohol" },
  { value: "Buffet", label: "Buffet" },
  { value: "Delivery", label: "Delivery" },
  { value: "Takeaway", label: "Takeaway" },
  { value: "Reservations available", label: "Reservations available" },
  { value: "BYOB", label: "BYOB" },
]
export const amenitiesOptions = [
  { value: "Television", label: "Television" },
  { value: "Music", label: "Music" },
  { value: "Live entertainment", label: "Live entertainment" },
  { value: "Fully licensed bar", label: "Fully licensed bar" },
  { value: "Outdoor seating", label: "Outdoor seating" },
  { value: "Private dining", label: "Private dining" },
  { value: "High chairs", label: "High chairs" },
  { value: "Toilets", label: "Toilets" },
  { value: "Good for kids", label: "Good for kids" },
  { value: "Casual", label: "Casual" },
  { value: "Cosy", label: "Cosy" },
]
export const parkingOptions = [
  { value: "on-site", label: "Parking available on-site" },
  { value: "street-parking", label: "Street parking" },
  { value: "free-parking", label: "Free parking" },
  { value: "disabled-parking", label: "Disabled parking" },
]
export const paymentOptions = [
  { value: "credit-card", label: "Credit card" },
  { value: "american-express", label: "American Express" },
  { value: "digital-payments", label: "Digital payments (Bank transfer)" },
  { value: "pay-later", label: "Buy now pay later" },
  { value: "cash", label: "Cash" },
  { value: "cash-only", label: "Cash only" },
]
export const mealOptions = [
  { value: "breakfast", label: "Breakfast" },
  { value: "brunch", label: "Brunch" },
  { value: "lunch", label: "Lunch" },
  { value: "dinner", label: "Dinner" },
  { value: "drinks", label: "Drinks" },
]
export const defaultTimes = [
  { value: "12:00am", label: "12:00 AM" },
  { value: "12:30am", label: "12:30 AM" },
  { value: "1:00am", label: "1:00 AM" },
  { value: "1:30am", label: "1:30 AM" },
  { value: "2:00am", label: "2:00 AM" },
  { value: "2:30am", label: "2:30 AM" },
  { value: "3:00am", label: "3:00 AM" },
  { value: "3:30am", label: "3:30 AM" },
  { value: "4:00am", label: "4:00 AM" },
  { value: "4:30am", label: "4:30 AM" },
  { value: "5:00am", label: "5:00 AM" },
  { value: "5:30am", label: "5:30 AM" },
  { value: "6:00am", label: "6:00 AM" },
  { value: "6:30am", label: "6:30 AM" },
  { value: "7:00am", label: "7:00 AM" },
  { value: "7:30am", label: "7:30 AM" },
  { value: "8:00am", label: "8:00 AM" },
  { value: "8:30am", label: "8:30 AM" },
  { value: "9:00am", label: "9:00 AM" },
  { value: "9:30am", label: "9:30 AM" },
  { value: "10:00am", label: "10:00 AM" },
  { value: "10:30am", label: "10:30 AM" },
  { value: "11:00am", label: "11:00 AM" },
  { value: "11:30am", label: "11:30 AM" },
  { value: "12:00pm", label: "12:00 PM" },
  { value: "12:30pm", label: "12:30 PM" },
  { value: "1:00pm", label: "1:00 PM" },
  { value: "1:30pm", label: "1:30 PM" },
  { value: "2:00pm", label: "2:00 PM" },
  { value: "2:30pm", label: "2:30 PM" },
  { value: "3:00pm", label: "3:00 PM" },
  { value: "3:30pm", label: "3:30 PM" },
  { value: "4:00pm", label: "4:00 PM" },
  { value: "4:30pm", label: "4:30 PM" },
  { value: "5:00pm", label: "5:00 PM" },
  { value: "5:30pm", label: "5:30 PM" },
  { value: "6:00pm", label: "6:00 PM" },
  { value: "6:30pm", label: "6:30 PM" },
  { value: "7:00pm", label: "7:00 PM" },
  { value: "7:30pm", label: "7:30 PM" },
  { value: "8:00pm", label: "8:00 PM" },
  { value: "8:30pm", label: "8:30 PM" },
  { value: "9:00pm", label: "9:00 PM" },
  { value: "9:30pm", label: "9:30 PM" },
  { value: "10:00pm", label: "10:00 PM" },
  { value: "10:30pm", label: "10:30 PM" },
  { value: "11:00pm", label: "11:00 PM" },
  { value: "11:30pm", label: "11:30 PM" },
  { value: "11:59pm", label: "11:59 PM" },
]
export const cuisines = [
  {
    value: "Afghan",
    label: "Afghan",
  },
  {
    value: "African",
    label: "African",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Algerian",
    label: "Algerian",
  },
  {
    value: "American",
    label: "American",
  },
  {
    value: "Apulian",
    label: "Apulian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Argentinian",
    label: "Argentinian",
  },
  {
    value: "Armenian",
    label: "Armenian",
  },
  {
    value: "Asian",
    label: "Asian",
  },
  {
    value: "Assyrian",
    label: "Assyrian",
  },
  {
    value: "Australian",
    label: "Australian",
  },
  {
    value: "Austrian",
    label: "Austrian",
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani",
  },
  {
    value: "Bahamian",
    label: "Bahamian",
  },
  {
    value: "Balti",
    label: "Balti",
  },
  {
    value: "Bangladeshi",
    label: "Bangladeshi",
  },
  {
    value: "Bar",
    label: "Bar",
  },
  {
    value: "Barbecue",
    label: "Barbecue",
  },
  {
    value: "Beer restaurants",
    label: "Beer restaurants",
  },
  {
    value: "Beijing cuisine",
    label: "Beijing cuisine",
  },
  {
    value: "Belgian",
    label: "Belgian",
  },
  {
    value: "Brazilian",
    label: "Brazilian",
  },
  {
    value: "Brew Pub",
    label: "Brew Pub",
  },
  {
    value: "British",
    label: "British",
  },
  {
    value: "Burmese",
    label: "Burmese",
  },
  {
    value: "Cafe",
    label: "Cafe",
  },
  {
    value: "Cajun & Creole",
    label: "Cajun & Creole",
  },
  {
    value: "Calabrian",
    label: "Calabrian",
  },
  {
    value: "Cambodian",
    label: "Cambodian",
  },
  {
    value: "Campania",
    label: "Campania",
  },
  {
    value: "Canadian",
    label: "Canadian",
  },
  {
    value: "Caribbean",
    label: "Caribbean",
  },
  {
    value: "Catalan",
    label: "Catalan",
  },
  {
    value: "Caucasian",
    label: "Caucasian",
  },
  {
    value: "Central American",
    label: "Central American",
  },
  {
    value: "Central Asian",
    label: "Central Asian",
  },
  {
    value: "Central European",
    label: "Central European",
  },
  {
    value: "Central-Italian",
    label: "Central-Italian",
  },
  {
    value: "Chilean",
    label: "Chilean",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Colombian",
    label: "Colombian",
  },
  {
    value: "Contemporary",
    label: "Contemporary",
  },
  {
    value: "Costa Rican",
    label: "Costa Rican",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Cuban",
    label: "Cuban",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Deli",
    label: "Deli",
  },
  {
    value: "Diner",
    label: "Diner",
  },
  {
    value: "Dining bars",
    label: "Dining bars",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "Eastern European",
    label: "Eastern European",
  },
  {
    value: "Ecuadorean",
    label: "Ecuadorean",
  },
  {
    value: "Egyptian",
    label: "Egyptian",
  },
  {
    value: "Emilian",
    label: "Emilian",
  },
  {
    value: "Ethiopian",
    label: "Ethiopian",
  },
  {
    value: "European",
    label: "European",
  },
  {
    value: "Fast food",
    label: "Fast food",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Fruit parlours",
    label: "Fruit parlours",
  },
  {
    value: "Fujian",
    label: "Fujian",
  },
  {
    value: "Fusion",
    label: "Fusion",
  },
  {
    value: "Gastropub",
    label: "Gastropub",
  },
  {
    value: "Georgian",
    label: "Georgian",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Grill",
    label: "Grill",
  },
  {
    value: "Guatemalan",
    label: "Guatemalan",
  },
  {
    value: "Hawaiian",
    label: "Hawaiian",
  },
  {
    value: "Healthy",
    label: "Healthy",
  },
  {
    value: "Hokkaido cuisine",
    label: "Hokkaido cuisine",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Indian",
    label: "Indian",
  },
  {
    value: "Indigenous",
    label: "Indigenous",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "International",
    label: "International",
  },
  {
    value: "Irish",
    label: "Irish",
  },
  {
    value: "Israeli",
    label: "Israeli",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Jamaican",
    label: "Jamaican",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Japanese Fusion",
    label: "Japanese Fusion",
  },
  {
    value: "Japanese sweets parlour",
    label: "Japanese sweets parlour",
  },
  {
    value: "Kaiseki",
    label: "Kaiseki",
  },
  {
    value: "Kappo",
    label: "Kappo",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Kyoto cuisine",
    label: "Kyoto cuisine",
  },
  {
    value: "Kyushu cuisine",
    label: "Kyushu cuisine",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Latvian",
    label: "Latvian",
  },
  {
    value: "Lazio",
    label: "Lazio",
  },
  {
    value: "Lebanese",
    label: "Lebanese",
  },
  {
    value: "Ligurian",
    label: "Ligurian",
  },
  {
    value: "Lombard",
    label: "Lombard",
  },
  {
    value: "Malaysian",
    label: "Malaysian",
  },
  {
    value: "Medicinal foods",
    label: "Medicinal foods",
  },
  {
    value: "Mediterranean",
    label: "Mediterranean",
  },
  {
    value: "Mexican",
    label: "Mexican",
  },
  {
    value: "Middle Eastern",
    label: "Middle Eastern",
  },
  {
    value: "Mongolian",
    label: "Mongolian",
  },
  {
    value: "Moroccan",
    label: "Moroccan",
  },
  {
    value: "Native American",
    label: "Native American",
  },
  {
    value: "Neapolitan",
    label: "Neapolitan",
  },
  {
    value: "Nepalese",
    label: "Nepalese",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nigerian",
    label: "Nigerian",
  },
  {
    value: "Northern-Italian",
    label: "Northern-Italian",
  },
  {
    value: "Norwegian",
    label: "Norwegian",
  },
  {
    value: "Pakistani",
    label: "Pakistani",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "Peruvian",
    label: "Peruvian",
  },
  {
    value: "Philippine",
    label: "Philippine",
  },
  {
    value: "Pizza",
    label: "Pizza",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Polynesian",
    label: "Polynesian",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Pub",
    label: "Pub",
  },
  {
    value: "Puerto Rican",
    label: "Puerto Rican",
  },
  {
    value: "Romagna",
    label: "Romagna",
  },
  {
    value: "Romana",
    label: "Romana",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Salvadoran",
    label: "Salvadoran",
  },
  {
    value: "Sardinian",
    label: "Sardinian",
  },
  {
    value: "Scandinavian",
    label: "Scandinavian",
  },
  {
    value: "Scottish",
    label: "Scottish",
  },
  {
    value: "Seafood",
    label: "Seafood",
  },
  {
    value: "Shojin",
    label: "Shojin",
  },
  {
    value: "Sicilian",
    label: "Sicilian",
  },
  {
    value: "Singaporean",
    label: "Singaporean",
  },
  {
    value: "Slovenian",
    label: "Slovenian",
  },
  {
    value: "Soups",
    label: "Soups",
  },
  {
    value: "South American",
    label: "South American",
  },
  {
    value: "Southern-Italian",
    label: "Southern-Italian",
  },
  {
    value: "Southwestern",
    label: "Southwestern",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "Sri Lankan",
    label: "Sri Lankan",
  },
  {
    value: "Steakhouse",
    label: "Steakhouse",
  },
  {
    value: "Street Food",
    label: "Street Food",
  },
  {
    value: "Sushi",
    label: "Sushi",
  },
  {
    value: "Swedish",
    label: "Swedish",
  },
  {
    value: "Swiss",
    label: "Swiss",
  },
  {
    value: "Taiwanese",
    label: "Taiwanese",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Tibetan",
    label: "Tibetan",
  },
  {
    value: "Tunisian",
    label: "Tunisian",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Tuscan",
    label: "Tuscan",
  },
  {
    value: "Ukrainian",
    label: "Ukrainian",
  },
  {
    value: "Uzbek",
    label: "Uzbek",
  },
  {
    value: "Venezuelan",
    label: "Venezuelan",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Welsh",
    label: "Welsh",
  },
  {
    value: "Wine Bar",
    label: "Wine Bar",
  },
  {
    value: "Xinjiang",
    label: "Xinjiang",
  },
  {
    value: "Yunnan",
    label: "Yunnan",
  },
]
